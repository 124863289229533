import React, { useCallback, useState } from 'react'

import { API, ShiftJobsAPI } from 'API'

import { Divider } from 'components/ui/__v2__/Divider'
import { SpacedColumn, SpacedRow } from 'components/ui/__v2__/Grid'

import { PAGE_SIZE } from 'constants/pagination'

import { useAppContext } from 'hooks'

import { SubmitButton } from './components/Controls'
import {
  Candidates,
  DraftAssignments,
  ExistingAssignments,
  GroupRestrictions,
  Positions,
  ShiftInfo,
} from './components'
import { ShiftAssignmentContext } from './context'
import { useAssignmentScratchPad, usePreselectShiftJob } from './hooks'
import { Layout } from './styles'

type Props = {
  shift: Gateway.Shift
}

export function InnerShiftAssign({ shift }: Props) {
  const {
    company: {
      identity,
      settings: { hasShiftValidation },
    },
  } = useAppContext()

  // NOTE: it is necessary to disable the main "Apply" button while validation is in progress
  const [deferredLoading, setDeferredLoading] = useState(false)

  const { shiftJobs } = ShiftJobsAPI.byShiftIdByCursor({
    shiftId: shift.id,
  })

  const [selectedShiftJob, setSelectedShiftJobId] = usePreselectShiftJob(
    shiftJobs,
  )

  const { modify, modifying } = ShiftJobsAPI.modifyAssignments()

  const state = useAssignmentScratchPad(selectedShiftJob, setSelectedShiftJobId)

  // TODO: remove this ugly hack when the BE will be able to return scheduleAcceptance
  //       in the schedule entity immediately after assigning an employee to the shift
  const { refetch } = API.Schedule.byCursor(
    {
      shiftJobId: selectedShiftJob?.id ?? '',
      paging: { limit: PAGE_SIZE['10'] },
    },
    {
      skip: true,
    },
  )

  // TODO: remove this when the BE will be able to return scheduleAcceptance
  //       in the schedule entity immediately after assigning an employee to the shift
  const refetchWithTimeout = useCallback(async () => {
    setTimeout(() => {
      refetch()
    }, 1000)
  }, [refetch])

  const handleConfirmSubmit = useCallback(
    async ({
      unassignConflictingSchedules,
    }: {
      unassignConflictingSchedules: boolean
    }) => {
      if (!state.selectedShiftJob) return

      const shiftJobUnfilledPositionsCount =
        state?.selectedShiftJob.unfilledPositions -
        state.draftAssignments.length +
        state.draftUnassignments.length

      const shiftUnfilledPositionsCount =
        shift.unfilledPositions -
        state.draftAssignments.length +
        state.draftUnassignments.length

      state.reset()

      await modify({
        addEmployees: state.draftAssignments,
        removeEmployees: state.draftUnassignments,
        shiftJobId: state.selectedShiftJob?.id,
        shiftId: shift.id,
        unassignConflictingSchedules,
        updater: {
          shiftJobUnfilledPositionsCount,
          shiftUnfilledPositionsCount,
        },
      })

      refetchWithTimeout()
    },
    [shift, state, modify, refetchWithTimeout],
  )

  const applyButtonDisabled =
    modifying || !state.hasUnappliedChanges || deferredLoading

  const availabilityAsHardConstraint =
    identity.Keywords || identity.Icc || identity.Tbs || identity.Fps

  return (
    <ShiftAssignmentContext.Provider
      value={{ ...state, availabilityAsHardConstraint }}
    >
      <SpacedColumn>
        <ShiftInfo shift={shift} />

        <SpacedRow alignItems="center">
          <Positions deferredLoading={deferredLoading} shiftJobs={shiftJobs} />
          <SubmitButton
            disabled={applyButtonDisabled}
            loading={modifying}
            onConfirm={handleConfirmSubmit}
          />
        </SpacedRow>
        <SpacedRow>
          <GroupRestrictions />
        </SpacedRow>

        <Divider mb={2} />
        <Layout>
          <ExistingAssignments
            hasShiftValidation={hasShiftValidation}
            shift={shift}
            onChangeDeferredLoading={setDeferredLoading}
          />
          <DraftAssignments
            deferredLoading={deferredLoading}
            hasShiftValidation={hasShiftValidation}
            shift={shift}
            onChangeDeferredLoading={setDeferredLoading}
          />
          <Candidates />
        </Layout>
      </SpacedColumn>
    </ShiftAssignmentContext.Provider>
  )
}
