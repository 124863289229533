export enum AldoPayrollRowChangeType {
  Add = 'ADD',
  None = 'NONE',
  Remove = 'REMOVE',
  Update = 'UPDATE',
}

export enum AldoPayrollRowSortingField {
  EmployeeCode = 'EMPLOYEE_CODE',
  StartDate = 'START_DATE',
}

export enum AldoPayrollRowTargetSystem {
  EcPayroll = 'EC_PAYROLL',
  EcTimeOff = 'EC_TIME_OFF',
}

export enum AutoSchedulingAssignmentsSortingField {
  CreatedAt = 'CREATED_AT',
  ShiftStartAt = 'SHIFT_START_AT',
}

export enum AutoSchedulingTaskFailureReason {
  EmptyInput = 'EMPTY_INPUT',
  TimeoutExceeded = 'TIMEOUT_EXCEEDED',
  Unspecified = 'UNSPECIFIED',
}

export enum AutoSchedulingTaskResult {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
  Unspecified = 'UNSPECIFIED',
}

export enum AutoSchedulingTaskSortingField {
  CreatedAt = 'CREATED_AT',
}

export enum AutoSchedulingTaskState {
  Applied = 'APPLIED',
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  Outdated = 'OUTDATED',
  Pending = 'PENDING',
}

export enum AutoSchedulingTaskStrategy {
  Assign = 'ASSIGN',
}

export enum AvailabilityRequestState {
  Approved = 'APPROVED',
  Outdated = 'OUTDATED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Withdrawn = 'WITHDRAWN',
}

export enum AvailableEmployeesForShiftJobCheck {
  False = 'FALSE',
  Skip = 'SKIP',
  True = 'TRUE',
}

export enum AvailableEmployeesForShiftJobSortingField {
  BidCreatedAt = 'BID_CREATED_AT',
  HasBid = 'HAS_BID',
  Wage = 'WAGE',
}

export enum AvailableShiftJobsByCursorSortingField {
  ShiftStartAt = 'SHIFT_START_AT',
}

export enum BulkUpdateCustomCvFieldValuesAccessLevel {
  Admin = 'ADMIN',
  DepartmentManager = 'DEPARTMENT_MANAGER',
  Employee = 'EMPLOYEE',
  LocationManager = 'LOCATION_MANAGER',
  Manager = 'MANAGER',
  Owner = 'OWNER',
  TemporaryAccessLevel = 'TEMPORARY_ACCESS_LEVEL',
}

export enum BulkUpdateCustomCvFieldValuesCustomFieldOperator {
  And = 'AND',
  Or = 'OR',
}

export enum BulkUpdateCustomCvFieldValuesStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  CurrentlyWorking = 'CURRENTLY_WORKING',
  Pending = 'PENDING',
}

export enum ClassifiedTimeSortingField {
  Date = 'DATE',
  EmployeeId = 'EMPLOYEE_ID',
  UpdatedAt = 'UPDATED_AT',
}

export enum ClassifierFieldSource {
  CustomCvField = 'CUSTOM_CV_FIELD',
}

export enum ClassifierFieldType {
  Checkbox = 'CHECKBOX',
  Dropdown = 'DROPDOWN',
}

export enum ClassifierSortingField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

export enum ClassifierSource {
  Branch = 'BRANCH',
  CustomCvField = 'CUSTOM_CV_FIELD',
  Department = 'DEPARTMENT',
  EmploymentTerm = 'EMPLOYMENT_TERM',
  Job = 'JOB',
}

export enum ConflictType {
  HardError = 'HARD_ERROR',
  SoftWarning = 'SOFT_WARNING',
}

export enum CustomCvFieldFieldType {
  Attachment = 'ATTACHMENT',
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  Decimal = 'DECIMAL',
  Dropdown = 'DROPDOWN',
  Email = 'EMAIL',
  Number = 'NUMBER',
  Percent = 'PERCENT',
  Phone = 'PHONE',
  Text = 'TEXT',
  Url = 'URL',
}

export enum CustomCvFieldValueSortingField {
  CreatedAt = 'CREATED_AT',
  CustomCvFieldId = 'CUSTOM_CV_FIELD_ID',
  EffectiveDatesEnd = 'EFFECTIVE_DATES_END',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

export enum CustomFieldFilterOperator {
  And = 'AND',
  Or = 'OR',
}

export enum EarningTypeSortingField {
  Code = 'CODE',
  Name = 'NAME',
}

export enum EmployeeJobSortingField {
  Id = 'ID',
}

export enum EmployeeJobWageType {
  Hour = 'HOUR',
  Year = 'YEAR',
}

export enum EmploymentLevelSettingSortingField {
  Id = 'ID',
}

export enum EmploymentLevelSortingField {
  Id = 'ID',
  SeniorityLevel = 'SENIORITY_LEVEL',
  Unspecified = 'UNSPECIFIED',
}

export enum EmploymentTermSortingField {
  EmploymentLevelId = 'EMPLOYMENT_LEVEL_ID',
  Id = 'ID',
  JobsEmployeeId = 'JOBS_EMPLOYEE_ID',
}

export enum EmploymentTypeSortingField {
  Id = 'ID',
  Name = 'NAME',
}

export enum EvaluatedTimeChangeType {
  Add = 'ADD',
  None = 'NONE',
  Remove = 'REMOVE',
  Unspecified = 'UNSPECIFIED',
  Update = 'UPDATE',
}

export enum EvaluatedTimeSortingField {
  Date = 'DATE',
  EmployeeId = 'EMPLOYEE_ID',
  UpdatedAt = 'UPDATED_AT',
}

export enum GardaSingleLocationReportKind {
  GardaDailyActivityLog = 'GARDA_DAILY_ACTIVITY_LOG',
  GardaDailyBreakSchedule = 'GARDA_DAILY_BREAK_SCHEDULE',
  GardaDailySchedule = 'GARDA_DAILY_SCHEDULE',
  GardaGrossHours = 'GARDA_GROSS_HOURS',
  GardaScheduleCertificationHours = 'GARDA_SCHEDULE_CERTIFICATION_HOURS',
  GardaShiftTradesAndDonations = 'GARDA_SHIFT_TRADES_AND_DONATIONS',
  GardaTimesheetDifferential = 'GARDA_TIMESHEET_DIFFERENTIAL',
  GardaTimeClockError = 'GARDA_TIME_CLOCK_ERROR',
  GardaWeeklyHeadcount = 'GARDA_WEEKLY_HEADCOUNT',
  GardaWeeklyHeadcountUnpublished = 'GARDA_WEEKLY_HEADCOUNT_UNPUBLISHED',
  GardaWeeklyHoursByLeaveType = 'GARDA_WEEKLY_HOURS_BY_LEAVE_TYPE',
  GardaWeeklyHoursByLocation = 'GARDA_WEEKLY_HOURS_BY_LOCATION',
  GardaWeeklyHoursByShiftTags = 'GARDA_WEEKLY_HOURS_BY_SHIFT_TAGS',
  GardaWeeklySchedule = 'GARDA_WEEKLY_SCHEDULE',
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
}

export enum GroupSortingField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

export enum ImportStatus {
  Failure = 'FAILURE',
  PartialSuccess = 'PARTIAL_SUCCESS',
  Success = 'SUCCESS',
}

export enum ImportStrategy {
  /** The system will import all the payload items that did not raise any errors and will report back on the ones that did */
  Partial = 'PARTIAL',
  /** The system will raise error and block importation if at least one item cannot be imported */
  Strict = 'STRICT',
}

export enum IntegrationDirection {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING',
}

export enum IntegrationEventCursorSortingField {
  CreatedAt = 'CREATED_AT',
}

export enum IntegrationEventSortingField {
  CreatedAt = 'CREATED_AT',
  Kind = 'KIND',
}

export enum IntegrationEventStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export enum IntegrationFileProcessingTrigger {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum IntegrationLogStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export enum IntegrationSortingField {
  CreatedAt = 'CREATED_AT',
}

export enum IntegrationTransferMethod {
  Api = 'API',
  File = 'FILE',
}

export enum IntegrationTransport {
  Ftp = 'FTP',
  Http = 'HTTP',
}

export enum IntegrationTrigger {
  Manual = 'MANUAL',
  Scheduled = 'SCHEDULED',
}

export enum PauseTimerState {
  Active = 'ACTIVE',
  Stopped = 'STOPPED',
}

export enum PayFrequencySortingField {
  Code = 'CODE',
  Name = 'NAME',
  StartDate = 'START_DATE',
  Type = 'TYPE',
}

export enum PayFrequencyType {
  Biweekly = 'BIWEEKLY',
  Weekly = 'WEEKLY',
}

export enum PayrollAccountSortingField {
  Code = 'CODE',
  Name = 'NAME',
}

export enum PayrollCalculationStatus {
  Done = 'done',
  Error = 'error',
  Started = 'started',
}

export enum PulledFileProcessingResult {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export enum PulledFileProcessingState {
  Enqueued = 'ENQUEUED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Staging = 'STAGING',
}

export enum PulledFileSortingField {
  CreatedAt = 'CREATED_AT',
}

export enum ReportFailureReason {
  Internal = 'INTERNAL',
  InvalidFilter = 'INVALID_FILTER',
}

export enum ReportKind {
  GardaAbsenteeism = 'GARDA_ABSENTEEISM',
  GardaDailyActivityLog = 'GARDA_DAILY_ACTIVITY_LOG',
  GardaDailyBreakSchedule = 'GARDA_DAILY_BREAK_SCHEDULE',
  GardaDailySchedule = 'GARDA_DAILY_SCHEDULE',
  GardaGrossHours = 'GARDA_GROSS_HOURS',
  GardaScheduleCertificationHours = 'GARDA_SCHEDULE_CERTIFICATION_HOURS',
  GardaShiftTradesAndDonations = 'GARDA_SHIFT_TRADES_AND_DONATIONS',
  GardaTimesheetDifferential = 'GARDA_TIMESHEET_DIFFERENTIAL',
  GardaTimeClockError = 'GARDA_TIME_CLOCK_ERROR',
  GardaWeeklyHeadcount = 'GARDA_WEEKLY_HEADCOUNT',
  GardaWeeklyHeadcountUnpublished = 'GARDA_WEEKLY_HEADCOUNT_UNPUBLISHED',
  GardaWeeklyHoursByLeaveType = 'GARDA_WEEKLY_HOURS_BY_LEAVE_TYPE',
  GardaWeeklyHoursByLocation = 'GARDA_WEEKLY_HOURS_BY_LOCATION',
  GardaWeeklyHoursByShiftTags = 'GARDA_WEEKLY_HOURS_BY_SHIFT_TAGS',
  GardaWeeklySchedule = 'GARDA_WEEKLY_SCHEDULE',
  ScheduledVsActualCosting = 'SCHEDULED_VS_ACTUAL_COSTING',
}

export enum ReportProcessingResult {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export enum ReportScheduleState {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export enum ReportState {
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

export enum Result {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export enum RuleContractedHoursType {
  FlatRate = 'FLAT_RATE',
  Multiplier = 'MULTIPLIER',
}

export enum RuleEmployeeFilterGroupCondition {
  All = 'ALL',
  Any = 'ANY',
}

export enum RuleOvertimePriority {
  HighestWage = 'HIGHEST_WAGE',
  Latest = 'LATEST',
  LowestWage = 'LOWEST_WAGE',
  Unscheduled = 'UNSCHEDULED',
}

export enum RuleOvertimeType {
  Daily = 'DAILY',
  Period = 'PERIOD',
  Weekly = 'WEEKLY',
}

export enum RuleParametersComparison {
  Between = 'BETWEEN',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  NotBetween = 'NOT_BETWEEN',
}

export enum RuleParametersCondition {
  Between = 'BETWEEN',
  Greater = 'GREATER',
  Lower = 'LOWER',
}

export enum RuleScheduledParametersDirection {
  AfterScheduled = 'AFTER_SCHEDULED',
  BeforeScheduled = 'BEFORE_SCHEDULED',
  Both = 'BOTH',
}

export enum RuleScheduledParametersTargetTime {
  Any = 'ANY',
  EndTime = 'END_TIME',
  StartTime = 'START_TIME',
}

export enum RuleScheduledParametersTimeCondition {
  Between = 'BETWEEN',
  Greater = 'GREATER',
  Lower = 'LOWER',
}

export enum RuleSortingField {
  Id = 'ID',
  Order = 'ORDER',
}

export enum RuleSplitShiftParametersGapCondition {
  Greater = 'GREATER',
  Lower = 'LOWER',
}

export enum RuleSplitShiftParametersTargetShift {
  Both = 'BOTH',
  First = 'FIRST',
  Second = 'SECOND',
}

export enum RuleTimeRangeParametersPercentType {
  Entity = 'ENTITY',
  TimeType = 'TIME_TYPE',
}

export enum RuleType {
  Basic = 'BASIC',
  ConsecutiveDays = 'CONSECUTIVE_DAYS',
  ContractedHours = 'CONTRACTED_HOURS',
  DayOfWeek = 'DAY_OF_WEEK',
  EmployeeAssignmentThreshold = 'EMPLOYEE_ASSIGNMENT_THRESHOLD',
  EmployeeDaysOffThreshold = 'EMPLOYEE_DAYS_OFF_THRESHOLD',
  EstablishPeriod = 'ESTABLISH_PERIOD',
  FetchTime = 'FETCH_TIME',
  Holiday = 'HOLIDAY',
  InitialTime = 'INITIAL_TIME',
  Overtime = 'OVERTIME',
  SameDay = 'SAME_DAY',
  Scheduled = 'SCHEDULED',
  ShiftHoliday = 'SHIFT_HOLIDAY',
  ShiftLengthThreshold = 'SHIFT_LENGTH_THRESHOLD',
  ShiftRestTime = 'SHIFT_REST_TIME',
  SplitShift = 'SPLIT_SHIFT',
  TimeRange = 'TIME_RANGE',
  TimeTypeMapping = 'TIME_TYPE_MAPPING',
  TopUp = 'TOP_UP',
  TotalTime = 'TOTAL_TIME',
  WeeklyTopUp = 'WEEKLY_TOP_UP',
}

export enum RulesetSortingField {
  Id = 'ID',
}

export enum RulesetState {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export enum ScheduleAcceptanceSortingField {
  CreatedAt = 'CREATED_AT',
  ShiftStartAt = 'SHIFT_START_AT',
}

export enum ScheduleAcceptanceState {
  Accepted = 'ACCEPTED',
  AutoAccepted = 'AUTO_ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  TimedOut = 'TIMED_OUT',
}

/** Conflict resolution strategy */
export enum ScheduleConflictType {
  /** Throw error on conflict */
  Error = 'ERROR',
  /** Ignore conflict */
  None = 'NONE',
  /** Split conflicting schedule */
  Split = 'SPLIT',
  /** Unassign EE from conflicting schedule */
  Unassign = 'UNASSIGN',
}

export enum ScheduleSortingField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  ShiftStartAt = 'SHIFT_START_AT',
}

export enum ScheduleState {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export enum ScheduledAndTotalCountsPositionCondition {
  All = 'ALL',
  Any = 'ANY',
  Empty = 'EMPTY',
  Full = 'FULL',
  Partial = 'PARTIAL',
}

export enum ShiftColor {
  Black = 'BLACK',
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  LightBlue = 'LIGHT_BLUE',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export enum ShiftCoverRequestsCountStatusType {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum ShiftJobGroupContext {
  BetterNot = 'BETTER_NOT',
  Must = 'MUST',
  MustNot = 'MUST_NOT',
  Preferred = 'PREFERRED',
}

export enum ShiftSortingField {
  HasOpenJob = 'HAS_OPEN_JOB',
  Name = 'NAME',
  Position = 'POSITION',
  StartAt = 'START_AT',
}

export enum ShiftTradeRequestsCountStatusType {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum ShiftsCopyMode {
  /** Add new shifts and ignore existing ones, will keep initial assigments in case of conflicts */
  Add = 'ADD',
  /** Delete conflicting shifts */
  Overwrite = 'OVERWRITE',
  /** Reassigns employees to the new shifts */
  Reassign = 'REASSIGN',
}

export enum ShiftsPositionCondition {
  All = 'ALL',
  Any = 'ANY',
  Empty = 'EMPTY',
  Full = 'FULL',
  Partial = 'PARTIAL',
}

export enum ShiftsShiftTagIdsFilterCondition {
  All = 'ALL',
  Any = 'ANY',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortingDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortingNulls {
  First = 'FIRST',
  Last = 'LAST',
}

export enum StartAutoSchedulingTaskPositions {
  All = 'ALL',
  Any = 'ANY',
  Empty = 'EMPTY',
  Full = 'FULL',
  Partial = 'PARTIAL',
}

export enum TimeBucketSortingField {
  ArchivedAt = 'ARCHIVED_AT',
  CreatedAt = 'CREATED_AT',
  EffectiveDatesStart = 'EFFECTIVE_DATES_START',
  Name = 'NAME',
}

/** remove after TimeEntriesCount will be used on frontend side */
export enum TimeCardsKindType {
  EmergencyOpenClock = 'emergency_open_clock',
  Manual = 'manual',
  OffSite = 'off_site',
  OpenClock = 'open_clock',
  Scheduled = 'scheduled',
}

/** remove after TimeEntriesCount will be used on frontend side */
export enum TimeCardsStatusType {
  Absence = 'absence',
  Active = 'active',
  Approved = 'approved',
  Discarded = 'discarded',
  Future = 'future',
  NotAvailable = 'not_available',
  Pending = 'pending',
}

export enum TimeClassificationDateType {
  EndTime = 'END_TIME',
  Split = 'SPLIT',
  StartTime = 'START_TIME',
}

export enum TimeClassificationFilterEntityType {
  TimeEntry = 'TIME_ENTRY',
  TimeOff = 'TIME_OFF',
}

export enum TimeClassificationParametersTimeTypeAction {
  Add = 'ADD',
  Both = 'BOTH',
  Set = 'SET',
}

export enum TimeEntryKind {
  Manual = 'MANUAL',
  OffSite = 'OFF_SITE',
  OpenClock = 'OPEN_CLOCK',
  Scheduled = 'SCHEDULED',
  WeeklyTimesheet = 'WEEKLY_TIMESHEET',
}

export enum TimeEntrySortingField {
  Id = 'ID',
}

export enum TimeEntryState {
  Absence = 'ABSENCE',
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Discarded = 'DISCARDED',
  Future = 'FUTURE',
  NotAvailable = 'NOT_AVAILABLE',
  Pending = 'PENDING',
  Stopped = 'STOPPED',
}

export enum TimeEntrySummarySortingField {
  EmployeeId = 'EMPLOYEE_ID',
}

export enum TimeEvaluationLogEvent {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export enum TimeEvaluationLogSortingField {
  CreatedAt = 'CREATED_AT',
}

export enum TimeTypeMappingType {
  Amount = 'AMOUNT',
  FlatRate = 'FLAT_RATE',
  Multiplier = 'MULTIPLIER',
}

export enum TimeoffRequestsCountStatusType {
  Accepted = 'accepted',
  Deleted = 'deleted',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum TimeoffState {
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum TimetypeSortingField {
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT',
}

export enum UserLogEvent {
  AcceptRelocationRequest = 'ACCEPT_RELOCATION_REQUEST',
  AccessLevelUpdate = 'ACCESS_LEVEL_UPDATE',
  AdminRelocationRequest = 'ADMIN_RELOCATION_REQUEST',
  BalanceAdjustment = 'BALANCE_ADJUSTMENT',
  CnesstRecordAdded = 'CNESST_RECORD_ADDED',
  CnesstRecordDeleted = 'CNESST_RECORD_DELETED',
  CnesstRecordUpdated = 'CNESST_RECORD_UPDATED',
  CustomCvFieldValueAttachmentAdded = 'CUSTOM_CV_FIELD_VALUE_ATTACHMENT_ADDED',
  CustomCvFieldValueAttachmentRemoved = 'CUSTOM_CV_FIELD_VALUE_ATTACHMENT_REMOVED',
  CustomCvFieldValueAttachmentUpdated = 'CUSTOM_CV_FIELD_VALUE_ATTACHMENT_UPDATED',
  CustomCvFieldValueUpdate = 'CUSTOM_CV_FIELD_VALUE_UPDATE',
  DisciplinaryRecordAdded = 'DISCIPLINARY_RECORD_ADDED',
  DisciplinaryRecordDeleted = 'DISCIPLINARY_RECORD_DELETED',
  DisciplinaryRecordUpdated = 'DISCIPLINARY_RECORD_UPDATED',
  EmployeeArchived = 'EMPLOYEE_ARCHIVED',
  EmployeeRestored = 'EMPLOYEE_RESTORED',
  EmployeeTagAdded = 'EMPLOYEE_TAG_ADDED',
  EmployeeTagRemoved = 'EMPLOYEE_TAG_REMOVED',
  EmployeeUpdated = 'EMPLOYEE_UPDATED',
  FaceIdReset = 'FACE_ID_RESET',
  InitRelocationRequest = 'INIT_RELOCATION_REQUEST',
  JobDeleted = 'JOB_DELETED',
  PasswordChanged = 'PASSWORD_CHANGED',
  PerfomanceReviewAdded = 'PERFOMANCE_REVIEW_ADDED',
  PerfomanceReviewDeleted = 'PERFOMANCE_REVIEW_DELETED',
  PerfomanceReviewUpdated = 'PERFOMANCE_REVIEW_UPDATED',
  ProfileUpdate = 'PROFILE_UPDATE',
  RejectRelocationRequest = 'REJECT_RELOCATION_REQUEST',
  RemovedFromCompany = 'REMOVED_FROM_COMPANY',
  RoleAdded = 'ROLE_ADDED',
  RoleRemoved = 'ROLE_REMOVED',
  SalaryRevisionAdded = 'SALARY_REVISION_ADDED',
  SalaryRevisionDeleted = 'SALARY_REVISION_DELETED',
  SalaryRevisionUpdated = 'SALARY_REVISION_UPDATED',
  SkillAdded = 'SKILL_ADDED',
  SkillDeleted = 'SKILL_DELETED',
  SkillUpdated = 'SKILL_UPDATED',
  SupervisorUpdate = 'SUPERVISOR_UPDATE',
  TimeoffAdded = 'TIMEOFF_ADDED',
  TimeoffDeleted = 'TIMEOFF_DELETED',
  TimeoffUpdated = 'TIMEOFF_UPDATED',
  TrainingAdded = 'TRAINING_ADDED',
  TrainingDeleted = 'TRAINING_DELETED',
  TrainingUpdated = 'TRAINING_UPDATED',
  Unspecified = 'UNSPECIFIED',
  UpdateEffectiveDate = 'UPDATE_EFFECTIVE_DATE',
  WageUpdated = 'WAGE_UPDATED',
  WiptecAbsenceAdded = 'WIPTEC_ABSENCE_ADDED',
  WiptecAbsenceDeleted = 'WIPTEC_ABSENCE_DELETED',
  WiptecAbsenceUpdated = 'WIPTEC_ABSENCE_UPDATED',
  WorkAgreementAdded = 'WORK_AGREEMENT_ADDED',
  WorkAgreementDeleted = 'WORK_AGREEMENT_DELETED',
  WorkAgreementUpdated = 'WORK_AGREEMENT_UPDATED',
}

export enum UsersCountStatusType {
  Active = 'active',
  Archived = 'archived',
  CurrentlyWorking = 'currently_working',
  Pending = 'pending',
}

export enum WeekDay {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export enum WorkPackageContext {
  BetterNot = 'BETTER_NOT',
  Must = 'MUST',
  MustNot = 'MUST_NOT',
  Preferred = 'PREFERRED',
}

export enum WorkPackageState {
  Conflicting = 'CONFLICTING',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
}

export enum WorkScheduleSortingField {
  EndDate = 'END_DATE',
  Name = 'NAME',
  Priority = 'PRIORITY',
  StartDate = 'START_DATE',
}

export enum WorkScheduleType {
  Biweekly = 'BIWEEKLY',
  Cycle = 'CYCLE',
  Weekly = 'WEEKLY',
}

export enum WorkWeekSortingField {
  Priority = 'PRIORITY',
  StartDay = 'START_DAY',
}

export enum WorkflowSortingField {
  Id = 'ID',
}

export enum WorkflowStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Running = 'RUNNING',
  Unspecified = 'UNSPECIFIED',
}

export enum WorkflowType {
  ShiftValidation = 'SHIFT_VALIDATION',
  TimeClassification = 'TIME_CLASSIFICATION',
  TimeEvaluation = 'TIME_EVALUATION',
}
