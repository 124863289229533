import { createContext, useContext } from 'react'

import { UseAssignmentScratchPadReturn } from './hooks'

type ShiftAssignmentContextType = {
  availabilityAsHardConstraint: boolean
} & UseAssignmentScratchPadReturn

export const ShiftAssignmentContext = createContext(
  {} as ShiftAssignmentContextType,
)

export function useShiftAssignmentContext() {
  return useContext(ShiftAssignmentContext)
}
